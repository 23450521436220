import React , {Fragment,useState} from "react";

const  {nanoid} = require("nanoid");



const CreateChat =()=>{



  const [buttonText,setButtonText]=useState("Create");
    //const [message,setMessage] = useState("");
    //const [sessionCode,setsessionCode] = useState("");
    const [disabled,setDisabled] = useState(false)
    const [url, setUrl] = useState("");
    const [hidden,setHidden] = useState("hidden")
    const sessioncode = nanoid(8);
    const [fileBase64,setfileBase64]= useState("");
    const [SelectedFile,setSelectedFile]= useState(null);
    const [isFileSucess, setisFileSucess] = useState(true);
    const [formData,setFormdata] = useState( {
       mySessionID : sessioncode,
       myMessage:"",
       myURLid:url,
       myFile:fileBase64,
    });


    const setseletedFile =(file) => {
      if(file.size > 1024*1024*10)
      {
        setisFileSucess(false)
      }
      else{ 
        FiletoBase64(file)
        setFormdata(
          {...formData,myFile:file}        
       )
       setisFileSucess(true)
       console.log(formData)
      }
      
     
    }


    const onchangeForm= (e) =>{
      //console.log(e.target.name + "  " + e.target.value)
      setUrl(nanoid(16))
      setFormdata(
         {...formData,[e.target.name]:e.target.value}
        
      )
    }

    const FiletoBase64 =(file) =>{
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend =()=>{
        setfileBase64(reader.result)
       // console.log(reader.result);
        //setfileBase64("file", reader.result);
      }

    }


    
                                                    
    const onSubmitForm = async (e) =>{
      e.preventDefault();
     
      if(buttonText ==="Create"){
       
        try{
          if(formData.myMessage.length > 0)
          {
              let message= formData.myMessage 
              let mysessioncode= formData.mySessionID  
              let myfile=formData.myFile      
              
              
              //console.log("URLLLLLLLLL------"+url);
              const body = {
                  message,
                  sessioncode:mysessioncode,
                  uni_url:url,
                  file:fileBase64
              };
            console.log(body);
  
             setDisabled(true)

             try{
                 const response =await fetch(  "https://server.ckveron.com/api/create", {
                 method: "POST",
                 headers: {
                   'Accept': '*/*',
                   'Content-Type': 'application/json',
                   'Access-Control-Allow-Origin': '*'
                 },
                 body: JSON.stringify(body),})

                 const data = await response.json()
                
                 if(data.message ==="Success create a chat")
                 {
                  document.getElementById("url").value = "https://"+window.location.host+"/Msg/" + url
                 
                  document.getElementById("url").type = "text";
                  setButtonText("Copy")
                  // document.getElementById("myMessage").value="";
                  // document.getElementById("myfile").value="";
                  setDisabled(false)
                
                 }

                 
                 

               
             }
             catch(e){
                
             }
             


  
          }
          

      }catch(err){
          console.log(err);
      }

      }
      else if(buttonText ==="Copy"){
        
        document.getElementById('url').select();
        navigator.clipboard.writeText(document.getElementById('url').value);
      }
      
        
        //console.log(formData)
       

        
    }
    return(
        <Fragment>
           
        <form className="d-grid gap-2 ms-3  me-5" onSubmit={onSubmitForm} >

          {/* <label for="SessionID">SessionID:</label>
          <input type="text" name='mySessionID' className="form-control" value={formData.mySessionID}
            placeholder={sessioncode}
            onChange={onchangeForm}
          ></input> */}

          <label for="message" >Message:</label>
          
          <textarea class="form-control" rows="6" id="myMessage" name="myMessage" value={ formData.myMessage}
            onChange={onchangeForm}
          ></textarea>
          

          <input type='file' className="form-control" name="file" id="myfile"
            accept=".jpg, .jpeg, .png, .pdf, .doc, .xlsx, .csv, .txt, .rar, .zip"
            onChange={(e) => setseletedFile(e.target.files[0])}></input>
            {isFileSucess ? null : <p className="success-message">File too large or not allowed type</p>}
          {/* <button className="btn btn-info form-control" onSubmit={onSubmitForm}>Create Link</button> */}
          <input  className="btn btn-info form-control"  type="submit" value={buttonText} disabled={disabled}/>

      

        </form>

        <input class="form-control" type={hidden} id="url" name="url" ></input>
        

        




            







        </Fragment>
    );
};

export default CreateChat;